.map-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  &:hover {
    outline: 1px solid rgba(94, 255, 0, 0.7);
  }
}

.gvnspBackground {
  // background-image: url('../../assets/images/map-bg-space.jpg');
  background-color: transparent;
  // background-repeat: repeat;
}

.active {
  outline: 1px solid rgba(255, 255, 0, 0.7);
  z-index: 20 !important;
  div {
    border: unset !important;
  }
}
.dragHandler {
  background-color: #06152b;
  border-radius: 4px;
  svg {
    font-size: 12px;
    width: 12px;
    height: 12px;
  }
}
