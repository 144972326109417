@import '../../assets/css/colors';

.button {
  font-size: 14px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 32px;
  margin: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #3a36db;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  text-align: center;
  text-decoration: none;
  .label {
    margin-left: 10px;
  }
}

.button-primary {
  color: #ffffff;
  background-color: $primaryBlue;
  &:active {
    color: $primaryBlue;
    background-color: $secondary;
  }
}
.button-border {
  color: #ffffff;
  background-color: $primaryBlue;
  &:active {
    color: $primaryBlue;
    background-color: $secondary;
  }
}

.button-secondary {
  color: $primaryBlue;
  background-color: $secondary;
  box-shadow: 12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
    -8px -8px 12px 0 rgba(0, 0, 0, 0.25) inset;
  &:active {
    color: $secondary;
    background-color: $primaryBlue;
  }
}

.button-link {
  color: initial;
  background-color: transparent;
  box-shadow: none;

  &:active {
    color: $secondary;
    background-color: transparent !important;
  }
  &:disabled {
    color: $secondary;
    background-color: transparent !important;
    opacity: 0.3;
  }
}

.button-small {
  font-size: 12px;
  padding: 10px 14px;
  background-color: #06152b;
  height: auto;
  margin: 0 4px;
  -webkit-box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.3);
  &:hover {
    -webkit-box-shadow: inset 0 0 7px 20px rgba(255, 255, 255, 0.3);
    -moz-box-shadow: inset 0 0 7px 20px rgba(255, 255, 255, 0.3);
    box-shadow: inset 0 0 7px 20px rgba(255, 255, 255, 0.3);
    // background-color: #2176ff;
  }
  &:hover:disabled {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  &.button:disabled,
  &.button[disabled] {
    // background-color: black;
    background-color: #06152b !important;
    opacity: 0.6;
    box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.05) !important;
    svg {
      opacity: 0.6;
    }
  }
}

.button-medium {
  font-size: 14px;
  padding: 11px 11px;
}

.button-large {
  font-size: 16px;
  padding: 12px 12px;
}

.button:disabled,
.button[disabled] {
  background-color: $primaryBlue;
  opacity: 0.6;
  pointer-events: none;
}

.circle {
  aspect-ratio: 1;
  border-radius: 50%;
}

.round {
  border-radius: 3em;
}

.link {
  background-color: transparent;
  border: none;
  box-shadow: none;
  text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px, rgba(255, 255, 255, 0.5) 0 1px,
    rgba(0, 0, 0, 0.3) -1px -2px;
}
