.landing-wrapper {
    .top-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        padding: 1rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
            max-width: 150px;
            width: 100%;
            height: auto;
        }
    }
    .pikalert-wrapper, .studio-wrapper {
        flex: 1;
        height: 100%;
    }
    h1 {
        font-size: 28px;
        text-transform: uppercase;
        font-weight: bold;
    }
    a, button {
        padding: 5px 20px;
        border-radius: 4px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        transition: gap 0.3s ease;
        &:hover {
            gap: 1rem;
        }
    }
    p {
        opacity: 0.75;
        margin-bottom: 1rem;
        mark {
            background-color: transparent;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                background-color: #FFEB3B;
                opacity: 0.65;
                height: 6px;
                width: 0;
                left: 0;
                bottom: 2px;
                z-index: -1;
                animation: underlineAnimation 0.3s ease-in-out forwards; /* Animation */
                animation-delay: 0.5s;
            }
        }
    }
    @keyframes underlineAnimation {
        0% {
            width: 0;
        }
        100% {
            width: 100%;
        }
    }
    .pikalert-wrapper {
        background-color: #efefef;
        overflow: hidden;
        &:hover {
            .bg-img {
                transition: all 0.3s ease;
                transform: scale(1.05) rotate(0.41deg);
            } 
        }
        .bg-img {
            background-image: url('../../assets/images/map2.jpg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            transition: all 0.3s ease;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 1;
            opacity: 0.65;
            filter: grayscale(1);
        }
        .cont-wrapper {
            z-index: 2;
            display: flex;
            position: relative;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            height: 100%;
            margin: 0 auto;
            .cont {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;

                background-color: white;
                width: 65%;
                margin: 0 auto;
                border-radius: 10px;
                padding: 2rem;
                gap: 1rem;
                a {
                    background-color: #081b37;
                    color: white;
                }
            }
        }
    }
    .studio-wrapper {
        overflow: hidden;
        // background-image: url('../../assets/images/sc.png');
        &:hover {
            .bg-img {
                transition: all 0.3s ease;
                transform: scale(1.05) rotate(-0.41deg);
            } 
        }
        .bg-img {
            background: linear-gradient(157deg, transparent -61%, #06152b 61%), url('../../assets/images/sc2.jpg');
            background-repeat: no-repeat;
            background-position: right top;
            background-size: cover;
            position: absolute;
            transition: all 0.3s ease;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 1;
        }
        .cont-wrapper {
            overflow: hidden;
            display: flex;
            position: relative;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            height: 100%;
            margin: 0 auto;
            .cont {
                display: flex;
                z-index: 2;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;

                background-color: transparent;
                width: 65%;
                margin: 0 auto;
                border-radius: 10px;
                padding: 2rem;
                gap: 1rem;
                color: white;
                p {
                    mark {
                        color: white;
                        &::after {
                            background-color: #0067ff;
                        }
                    }
                    color: white;
                }
            }
        }
    }
}