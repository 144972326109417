.slider {
  width: 100%;
  height: 6px;
}

.track {
  background: #ddd; 
  height: 6px;
  border-radius: 5px;
}

.react-slider .track--active {
  background: #007bff;
}

.thumb {
  width: 20px;
  height: 20px;
  margin-top: -7px;
  background: #007bff;
  border-radius: 50%;
  cursor: pointer;
}

.thumb-0 {
  background: #007bff;
}

.thumb-1 {
  background: #081b37;
}