.rc-slider{
  position: relative;
}
.rc-slider-handle {
  width: 5px;
  border: none;
  z-index: 1;
}
.rc-slider-track{
  background-color: #600d0d;
  height: 3px;
}
