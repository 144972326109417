$primaryBlue: #2176ff;
$primaryPurple: #4e36be;
$secondary: #050f1e;
$snow: #fbfdfe;
$lightGrey: #f3f3f3;
$grey: #d9d9d9;
$primaryGrey: #888888;
$bigFont: #06152b;
$dark: #282828;
$white: #ffffff;
$light: #ffffff88;
$darkGrey: #e6e6e6;
$darkText: #5a5a5a;
$secondaryPurple: #6445f1;
