.image_element {
  width: 100%;
  height: 100%;
}
.canvasElement {
  cursor: inherit !important;
  // border: 1px solid transparent;
  border-radius: 2px;
  &.elementHover {
    &:hover {
      outline: 1px solid rgba(94, 255, 0, 0.7) !important;
    }

    &.active {
      outline: 1px solid rgba(255, 255, 0, 0.7) !important;
      z-index: 20;
    }
  }
  overflow: clip;
}
.clip {
  background-clip: text !important;
  -o-background-clip: text !important;
  -moz-background-clip: text !important;
  -webkit-background-clip: text !important;
}
