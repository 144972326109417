.skeleton-wrapper {
  background-color: rgba(5, 15, 30, 1);
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  .playground-skeleton {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    .map-skeleton {
      display: flex;
      flex-direction: column;
      width: 45%;
    }
    .grid-wrapper-skeleton {
      width: 55%;
      display: flex;
      flex-direction: column;
    }
  }
  span {
    background-color: rgba(20, 39, 68, 1);
    min-width: 50px;
    height: 30px;
    display: inline-block;
    border-radius: 4px;
    animation: skeleton-pulse 1.5s ease-in-out infinite;
  }
  .actions-sk {
    width: 330px;
    margin-left: 1rem;
  }
  .grid-actions-sk {
    width: 330px;
    margin-left: auto;
    margin-right: 1rem;
  }
  .grid-sk {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 2rem;
    span {
      width: calc(50% - 1rem);
    }
  }
  .playground-sk-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .left-actions-sk {
      width: calc(10% - 2rem);
      height: 400px;
      margin: 2rem 1rem 0 1rem;
    }
    .playground-sk {
      width: 90%;
      height: 400px;
      margin-top: 2rem;
    }
  }
  .timeline-wrapper-skeleton {
    display: flex;
    align-items: flex-start;
    margin-top: 4rem;
    .items-skeleton {
      width: 15%;
      padding: 1rem;
      span {
        margin-bottom: 1rem;
        &:first-child {
          width: 70%;
        }
      }
      span:not(:first-child) {
        width: 100%;
      }
    }
    .timeline-skeleton {
      width: 85%;
      padding: 1rem;
      padding-top: 35px;
      margin-top: 2rem;
      span {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

/* Define the animation */
@keyframes skeleton-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
