/* To center the spinner*/
.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3a36db;
  margin: auto;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  margin: auto;
  min-height: 50vh;
  display: flex;
  align-items: center;
}
