.palette-legend {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #333;

  canvas {
    border-radius: 4px;
    display: block;
  }
}
