.map {
  height: 500px;
  aspect-ratio: 16 / 9;
  margin: auto;
  border: 1px solid black;
  position: relative;
  margin-top: 10px;
}
.fullscreenIcon {
  z-index: 99999;
  align-self: flex-end;
  cursor: pointer;
}
//noinspection CssInvalidPropertyValue
.timelineHolderProject {
  border: 0.5px solid #2b3441;
  position: absolute;
  background-color: #06152b;
  display: flex;
  padding: 0 0 15px;
  width: 100%;
  box-sizing: content-box;
  height: -webkit-fill-available;
}
.timelineHolder {
  border: 0.5px solid #2b3441;
  position: absolute;
  background-color: #06152b;
  display: flex;
  width: calc(100% - 3px);
  // min-width: 1120px;
  box-sizing: content-box;
  height: fit-content;

  &::-webkit-scrollbar {
    z-index: 999;
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: black;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: white;
  }
}

///noinspection ALL
.timelineHolder {
  min-height: calc(100% - 41px);
}

.controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.controlElement,
.player,
.zoomControls {
  flex: 1 0 33.333%;
}
.player {
  justify-content: center;
}
.playControls {
  display: flex;
  align-items: center;
  justify-content: center;
}
.zoomControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: fit-content;
  position: absolute;
  right: 0;
  z-index: 9;
  background: #050f1e;
}
.studio {
  // border: 0.5px solid #2b3441;
  border: 0.5px solid #2e3744;
  font-kerning: none;
  font-optical-sizing: none;
  -webkit-font-smoothing: antialiased;
  text-decoration-skip-ink: none;

  // aspect-ratio: 16/9;
  .controls {
    padding: 5px 20px;
    display: flex;
    flex: 1 0 33%;
    justify-content: space-between;
  }

  .canvasContainer {
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    .controlBar {
      position: absolute;
      justify-content: flex-end;
      overflow: hidden;
      display: flex;
      bottom: 0;
      height: 40px;
      right: 0;
      z-index: 30;

      .fullscreenIcon {
        z-index: 99999;
        align-self: flex-end;
        cursor: pointer;
      }
    }
    .canvas {
      // height: 100%;
      // aspect-ratio: 16/9;
      // border: 1px solid white;
      margin: auto;
      background: black;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      &:focus-visible {
        outline: none;
      }

      .controlBar {
        position: absolute;
        justify-content: flex-end;
        overflow: hidden;
        display: flex;
        bottom: 0;
        height: 0;
        right: 0;
        z-index: 30;
      }

      &:hover {
        .controlBar {
          padding: 5px;
          height: 40px;
          transition: all 0.2s;
        }
      }
    }
  }
}

.dark {
  background-color: #050f1e;
  position: relative;
}

.below-top {
  margin-top: 43px;
  padding-top: 5px;
}
.laneWrapper {
  z-index: 0;
  overflow-x: auto;
  overflow-y: clip;
  width: 100%;
  padding: 0 20px 0 20px;
  position: relative;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: black;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: white;
  }
  .borderLeft,
  .borderRight {
    height: calc(100% - 40px);
    width: 0.5px;
    position: absolute;
    bottom: 0;
  }
  .borderLeft {
    left: 0;
    background-color: rgba(0, 128, 0, 0.7);
  }
  .borderRight {
    right: 0;
    background-color: rgba(255, 2, 42, 0.7);
  }
  .overlay {
    pointer-events: none;
    position: absolute;
    height: calc(100% - 40px);
    z-index: 9;
    bottom: 0;
    background-color: rgba(128, 128, 128, 0.2);
  }
}
.offsetInput {
  input {
    font-size: 1.875rem;
  }
}
.offsetButton {
  padding: 5px;
  width: 50px;
  &.offsetActive {
    background-color: black;
  }
}
