html,
body,
div#root {
  height: -webkit-fill-available;
  min-height: 100%;
}

/*::-webkit-scrollbar {
  height: 12px;
  width: 8px;
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  width: 6px;
  border-radius: 1ex;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
}*/
.dark-background {
  background-color: #050f1e;
  color: #8d949f !important;
  /* margin-left: 40px; */
  /* width: calc(100% - 104px); */
  /* box-sizing: content-box; */
  height: auto;
  display: flex;
}
/* ::-webkit-scrollbar-corner {
  background: #000;
} */
/*Hide maptiler bottom watermark */
div.maplibregl-ctrl-bottom-right {
  display: none !important;
}

.Toastify__progress-bar {
  height: 2px !important;
  background: #3a36db !important;
}

/*Hide openlayers watermark */
/*TO BE DONE maybe some Api attribution null on library ol */
.ol-attribution {
  display: none !important;
}
