@import 'src/assets/css/colors';

.ReactModal__Overlay {
  background-color: rgb(17 24 39 / 0.5) !important;
  // background-color: rgba(255, 255, 255, 0.1) !important;
  z-index: 1;
}
.SendAssemblyModal__modal-wrap {
  height: auto !important;
  width: 350px !important;
  position: fixed !important;
  top: 50%;
  margin-top: -97px !important;
  left: 50%;
  margin-left: -175px !important;
  .header-bar {
    border: none !important;
  }
}
#Modal {
  overflow: auto;
  background: #1f2a40 !important;
  border-radius: 10px !important;
  z-index: 9;
  padding: 0 !important;
  width: fit-content;
  margin: 50px auto auto;
  color: white;
  height: calc(100vh - 100px);
  .header-bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1f2a40;
    padding: 20px;
    text-transform: uppercase;
    .close {
      cursor: pointer;
    }
  }
  .SendAssemblyModal__modal-body.modal-body {
    height: auto !important;
    text-align: center;
    & + div.modal-footer {
      border: none;
      padding: 10px;
      justify-content: flex-end;
    }
  }

  .modal-body {
    padding: 10px 20px;
    height: calc(100% - 115px - 1rem);
    overflow: auto;
    input:not(.ant-select-selection-search-input){
      background-color: transparent;
      border: 1px solid rgb(77 86 104);
      &:focus {
        outline: none;
        border-color: #1c64f2;
        box-shadow: none;
      }
    }
    textarea {
      background-color: transparent !important;
      border: 1px solid rgb(77, 86, 104);
      &:focus {
        outline: none;
        border-color: #1c64f2;
        box-shadow: none;
      }
    }
    select {
      &:focus {
        outline: none;
        border-color: #1c64f2;
        box-shadow: none;
      }
    }
  }
  .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    padding: 20px;
    .prev-next {
      button {
        width: 130px;
      }
    }
    .button-holder {
      white-space: nowrap;
      display: flex;
      align-items: center;
      button {
        padding: 0 20px;
        color: white;
        margin: 0;
        margin-right: 0.5rem;
        svg {
          font-size: 20px;
          margin-right: 10px;
        }
      }
      .button.button-link {
        padding: 0;
      }
    }
  }
}
