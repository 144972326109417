// body, p, strong, span {
//   color: #33334e;
// }
.properties__tabs_wrapper {
  div[aria-label='Tabs'] {
    display: flex;
    justify-content: space-around;
    border: none;
    .text-blue-600 {
      color: unset;
    }
    .border-blue-600 {
      border-color: unset;
    }
    button {
      outline: none !important;
    }
  }
}

.bg-yellow-400 {
  background-color: #fa5656;
}

div[data-testid='tooltip'] {
  z-index: 9;
}
div[data-testid='tooltip'].bg-gray-900 {
  background-color: #1f2a40;
  div {
    background-color: #1f2a40;
  }
}

.dark {
  .dark\:bg-gray-700 {
    background-color: #1f2a40;
  }
}

@keyframes FADE_IN {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes FADE_OUT {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.maptiler-geocoder-results {
  z-index: 9999999999 !important;
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}

.ol-zoom {
  top: unset !important;
  left: unset !important;
  bottom: 0.5em !important;
  right: 0.5em !important;
  flex-direction: column;
}

.left-menu {
  .itm {
    svg, .anticon {
      font-size: 20px;
      & + span {
        position: absolute;
        left: 65px;
        white-space: nowrap;
        background-color: #081b37;
        color: white;
        padding: 4px 10px;
        border-radius: 4px;
        display: none;
        &::before {
          position: absolute;
          content: '';
          top: 10px;
          left: -7px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 10px 5px 0;
          border-color: transparent #081b37 transparent transparent;
          transform: rotate(0deg);
        }
      }
    }
  }
  .itm:hover {
    span {
      display: block;
    }
  }
}

.triangle-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 24px 0 0;
  border-color: #ffffff transparent transparent transparent;
  transform: rotate(0deg);
  transform: scaleX(-1);

  position: absolute;
  left: -30px;
  top: 0;
}

.triangle-right {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 24px 24px 0;
  border-color: transparent #ffffff transparent transparent;
  transform: rotate(0deg);
  transform: scaleX(-1);

  position: absolute;
  right: -30px;
  top: 0;
}

.custom-checkbox-buttons {
  display: flex;
  flex-direction: column;
  input[type='checkbox'] + label {
    color: white;
    .layer-icon {
      svg {
        display: none;
      }
      background-image: url('../src/assets/images/no-eye.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
      width: 20px;
      height: 20px;
      filter: invert(1);
    }
  }

  input[type='checkbox']:checked + label {
    background-color: #081b37;
    .layer-icon {
      background: none;
      color: white;
      filter: unset;
      svg {
        display: block;
      }
    }
  }
}

.ol-control {
  position: absolute;
  border-radius: 4px;
}

.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: gray;
  font-weight: bold;
  text-decoration: none;
  font-size: inherit;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: 0.4em;
  background-color: white;
  border: none;
  border-radius: 2px;
}

// Full screen styles
.fullScreen_container {
  position: fixed;
  top: 0;
  left: 0;
  // min-width: 100vw !important;
  // min-height: 100vh !important;
  background-color: black !important;
  // scale: 1.222;
  z-index: 900000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullScreen_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 99999;
  background-color: black !important;
}

.noFullScreen_container {
  width: 100%;
  /*top: 0;
  left: 0;*/
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.project-toast {
  font-size: 16px;
  width: 600px;
  left: calc(-50%);
}
.ant-table-content {
  font-size: 12px;
  .ant-table-thead {
    .ant-table-cell {
      text-transform: uppercase;
    }
  }
}

.bubble {
  position: relative;
  background: #e3eeff;
  border-radius: 10px;
  padding: 10px;
  min-width: 150px;
  //box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.025); /* Thin shadow for the bubble */
  // margin: 20px;
}

/* Bubble tail */
.bubble:after {
  content: '';
  position: absolute;
  top: 50%; /* Position the tail vertically centered */
  left: -15px; /* Adjust the horizontal position of the tail */
  transform: translateY(-50%); /* Center the tail vertically */
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #e3eeff transparent transparent; /* Tail pointing to the left */
}
.layer-drawer {
  .ant-drawer-body {
    padding: 40px 0;
    svg {
      opacity: 0.65;
      font-size: 18px;
    }
    .pointer-events-auto.overflow-hidden {
      &:hover {
        svg {
          opacity: 1;
        }
      }
      .label {
        font-size: 17px;
        font-size: 500;
      }
    }
  }
}

.site-table-modal > .ant-modal-content {
  padding: 0;
}