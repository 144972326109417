$searchDots: dots 1s steps(5, end) infinite;
$scaleIn: transformer 0.1s ease-in;
$blink: blinking 1s infinite;
.dots-animation{
  animation: $searchDots;
}
@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
            .25em 0 0 white,
            .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
            .25em 0 0 white,
            .5em 0 0 white;}}
@-webkit-keyframes transformer {
  from {
    -webkit-transform: scale(0.1);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes transformer {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes blinking {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
