.scene-container{
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  margin: auto;
}
.text-panel-container {
  span {
    vertical-align: top;
  }
}
