.controlButton {
  display: flex;
  align-items: center;
  height: 40px;
  margin: auto 10px;
  &.bordered{
    border: 1px solid #ffffffad;
    background-color: #ffffffad;
    border-radius: 4px;
    color: black;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.replay{
    height: 24px;
    width: 24px;
    border: 1px solid #ffffffad;
    background-color: #ffffffad;
    border-radius: 4px;
    svg {
      path  {
        background-color: #ffffffad;
      }
      path:last-child  {
        fill: #050f1e;
      }
    }
  }
  svg {
    path {
      fill: #ffffffad;
    }
  }
  &:hover {
    cursor: pointer;
    svg {
      path {
        fill: white;
      }
    }
  }
  &:nth-child(3) {
    width: 24px;
  }
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
